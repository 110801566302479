import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import AccordionItem from "./AccordionItem";

class FaqRoll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: -1
    };
  }

  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className="faq">
        {posts &&
          posts.map(({ node: post }, index) => {
            const showDescription = index === this.state.activeIndex ? "show-description" : "";
            const fontWeightBold = index === this.state.activeIndex ? "font-weight-bold" : "";
            const ariaExpanded = index === this.state.activeIndex ? "true" : "false";
            const item = {
              title: post.frontmatter.question,
              answer: post.html,
            }
            return (
              <AccordionItem
                showDescription={showDescription}
                fontWeightBold={fontWeightBold}
                ariaExpanded={ariaExpanded}
                item={item}
                index={index}
                key={index}
                onClick={() => {
                  if (this.state.activeIndex == index){
                    this.setState({ activeIndex: -1 })
                  }else{
                    this.setState({ activeIndex: index })
                  }
                }}
              />
            )
          })
        }
      </div>
    )
  }
}

FaqRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query FaqRollQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "faq-post" } } }
        ) {
          edges {
            node {
              html
              frontmatter {
                question
                templateKey
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <FaqRoll data={data} count={count} />}
  />
)
