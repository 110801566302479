import React from "react";
import Content, { HTMLContent } from '../components/Content'

const AccordionItem = ({
  showDescription,
  ariaExpanded,
  fontWeightBold,
  item,
  index,
  onClick,
}) => (
  <div className="box faq__question" key={item.title}>
    <dt>
      <button
        aria-expanded={ariaExpanded}
        aria-controls={`faq${index + 1}_desc`}
        data-qa="faq__question-button"
        className={`faq__question-button ${fontWeightBold}`}
        onClick={onClick}
      >
        {item.title}
      </button>
    </dt>
    <dd className={`faq__desc ${showDescription}`} >
      <HTMLContent id={`faq${index + 1}_desc`} data-qa="faq__desc" content={item.answer} />
      {item.link &&
        <div className="has-text-centered">
            <br/>
            <a className="button is-primary" href={item.link} >{item.linkLabel}</a>
        </div>
      }
    </dd>
  </div>
);

export default AccordionItem;